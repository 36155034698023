import React, { useState, useEffect } from "react"
import { ReactComponent as Success } from "./images/success.svg"
import { ReactComponent as Printer } from "./images/printer.svg"
import { ReactComponent as Goback } from "./images/goback.svg"
import { ReactComponent as Spinner } from "./images/spinner.svg"
import { ReactComponent as SpinnerGray } from "./images/spinner-gray.svg"
import { ReactComponent as ToastIcon } from "./images/toasticon.svg"
import { ReactComponent as Share } from "./images/share.svg"
import { ReactComponent as Close } from "./images/close.svg"
import { BASE_URL } from '../../../Store/snippets';
import MaskedInput from 'react-text-mask';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { WHATSAPP_API_URL } from "../../../constants/app"

import 'react-toastify/dist/ReactToastify.css';
import style from "./styles/sendCheck.module.scss"
import mainStyle from "./styles/check.module.scss"

const Check = ({context, history}) => {
  const { t } = useTranslation()

  const LANGUAGES = {
    KAZAKH: 1,
    RUSSIAN: 2,
  };

  const SEND_METHODS = {
    EMAIL: 1,
    WHATSAPP: 2,
  };

  let kkm = JSON.parse(localStorage.getItem('KKM'))
  let isElectron = require("is-electron");

  const [lang, setLang] = useState(LANGUAGES.RUSSIAN)
  const [active, setActive] = useState(SEND_METHODS.EMAIL)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(false)
  const [sending, setSending] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (window.document) document.body.style.overflow = show ? 'hidden' : 'auto'
    return () => document.body.style.overflow = 'auto'
  }, [show])

  let checkData = JSON.parse(localStorage.getItem('checkData'))
  let kazCheckData = JSON.parse(localStorage.getItem('kazCheckData'))
  
  const sendEmail = () => {
    const Token = localStorage.getItem("tokken");

    if (email.length) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        setEmailError(t('other.check_email_correctness'))
      } else {
        setEmailError(false)
        setSending(true)
        let data = {
          "action": "email",
          "recipient": email
        }
        fetch(`${BASE_URL}/www/sendcheck/${checkData.CheckId}`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`,
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body:  JSON.stringify(data)
        })
        .then((res) => {
          return res.json()
        })
        .then((d) => {
          setSending(false)
          if (d.Status === 200) {
            setEmail("")
            toast.success(`${ t('other.check_email_sent_successfully') }: ${email}.`, { theme: "dark" })
          }
        })
      }
    } else {
      setEmailError(t('other.enter_email'))
    }
  }

  const sendWa = () => {
    if (phone.replace(/\D+/g, '').length) {
      if (phone.replace(/\D+/g, '').length !== 11) {
        setPhoneError(t('other.check_phone_number_correctness'))
      } else {
        setEmailError(false)
        setSending(true)
        setTimeout(() => {
          setSending(false)
          window.open(`${WHATSAPP_API_URL}/send?phone=${phone}&text=Номер чека: ${checkData.CheckId}. Проверьте чек, перейдя по ссылке: ${checkData.CheckLink}`)
          setPhone("")
          toast.success(`${ t('other.check_sent_successfully_to_number') }: ${phone}.`, { theme: "dark" })
        }, 1000);
      }
    } else {
      setPhoneError(t('other.enter_phone_number'))
    }
  }

  const MyIcon = () => <ToastIcon />

  const printCheck = () => {
    toast.success(`${ t('other.check_sent_for_printing') }.`, { theme: "dark" })
    if (isElectron()) {
      context.electronShowCheck()
    } else {
      context.showCheck()
    }
  }

  const sendCheck = () => setShow(true)

  const stop = e => e.stopPropagation()

  const closeModal = () => setShow(false)


  const shareLink = () => {
    navigator.share({
      url: checkData.CheckLink
    })
    .then(() => console.log('successful'))
    .catch(err => console.error(err));
  }

  const goBack = () => history.goBack()

  const goCashbox = () => history.push(`/www/kkms/${kkm.Id}`)

  const currentCheckData = lang === LANGUAGES.RUSSIAN ? checkData : kazCheckData;

  return (
    <div className={mainStyle.main}>

      <div className={style.mobileHeader}>
        <p>{ t('receipt.operation_successful') }</p>
        <Close onClick={() => goBack()} />
      </div>

      <div className={style.checkWrapper}>
        <div className={style.checkLang}>
          <p className={style.langTitle}>
            { t('receipt.select_receipt_language') }
          </p>
          <div className={style.holder}>
            <button className={lang === LANGUAGES.RUSSIAN ? style.emailActive : style.email} onClick={() => setLang(LANGUAGES.RUSSIAN)}>Русский</button>
            <button className={lang === LANGUAGES.KAZAKH ? style.wtspActive : style.wtsp} onClick={() => setLang(LANGUAGES.KAZAKH)}>Қазақша</button>
          </div>
        </div>
        <div className={style.check}>
          {
            currentCheckData && currentCheckData.CheckImg ? (
              <img
                id="receipt"
                src={`data:image/png;base64,${currentCheckData.CheckImg}`}
                alt="Check"
              />
            ) : (
              <div className={mainStyle.spinnerWrapper}>
                <SpinnerGray />
                <p className={mainStyle.text}>{ t('main.loading') }</p>
              </div>
            )
          }
        </div>
      </div>

      <div className={style.mobileButtons}>
        <button className={style.newSale} onClick={() => goBack()}>{ t('receipt.new_sale') }</button>
        <button className={style.goBack} onClick={() => goCashbox()}>{ t('receipt.return_to_cash_register') }</button>
      </div>

      <div className={style.form}>
        <div className={style.formHeader}>
          <Success />
          <p>{ t('receipt.operation_successful') }</p>
        </div>
        <div className={style.formBody}>
          <div className={style.holder}>
            <button className={active === SEND_METHODS.EMAIL ? style.emailActive : style.email} onClick={() => setActive(SEND_METHODS.EMAIL)}>Email</button>
            <button className={active === SEND_METHODS.WHATSAPP ? style.wtspActive : style.wtsp} onClick={() => setActive(SEND_METHODS.WHATSAPP)}>WhatsApp</button>
          </div>
          {
            active === SEND_METHODS.EMAIL ? (
              <div className={style.inputWrapper}>
                <label htmlFor="">{ t('receipt.send_receipt_email') }</label>
                <div className={style.sendWrapper}>
                  <input
                    type="text"
                    placeholder="example@kassa.kz"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={emailError ? style.errorInput : ''}
                  />
                  <button onClick={() => sendEmail()} className={sending ? style.sending : ''}>
                    {sending ? (
                      <Spinner />
                    ) : (
                      t('receipt.send')
                    )}
                  </button>
                </div>
                {
                  emailError && <p className={style.error}>{emailError}</p>
                }
              </div>
            ) : (
              <div className={style.inputWrapper}>
                <label htmlFor="">{ t('receipt.send_receipt_whatsapp') }</label>
                <div className={style.sendWrapper}>
                  <MaskedInput
                    showMask={false}
                    guide={true}
                    placeholderChar={'\u2000'}
                    mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                    inputMode="tel"
                    id="inputPhone"
                    name="number"
                    className={phoneError ? style.errorInput : ''}
                    placeholder="+7 (777) 000-00-00"
                    autoComplete="phone"
                    required
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    onFocus={(e) => e.target.value = "+7"}
                    onBlur={(e) => e.target.placeholder = "+7 (777) 000-00-00"}
                  />
                  <button onClick={() => sendWa()} className={sending && style.sending}>
                    {sending ? (
                      <Spinner />
                    ) : (
                      t('receipt.send')
                    )}
                  </button>
                </div>{
                  phoneError && <p className={style.error}>{phoneError}</p>
                }
              </div>
            )
          }
        </div>
        <div className={style.formFooter}>
          <button onClick={() => printCheck()}>
            <Printer />
            { t('receipt.print') }
          </button>
          <button onClick={() => goBack()}>
            <Goback />
            { t('receipt.return_to_cash_register') }
          </button>
        </div>
      </div>

      <div className={style.mobileActions}>
        <button onClick={() => printCheck()}>{ t('receipt.printing') }</button>
        <button onClick={() => sendCheck()}>{ t('receipt.send') }</button>
        <button onClick={() => shareLink()}><Share/></button>
      </div>

      {
        show && (
          <div className={style.sendCheckWrapper} onClick={() => closeModal()}>
            <div className={style.sendCheckModal} onClick={(e) => stop(e)}>
              <div className={style.formTitle}>
                <p>Отправка чека</p>
              </div>
              <div className={style.formBody}>
                <div className={style.holder}>
                  <button className={active === SEND_METHODS.EMAIL ? style.emailActive : style.email} onClick={() => setActive(SEND_METHODS.EMAIL)}>Email</button>
                  <button className={active === SEND_METHODS.WHATSAPP ? style.wtspActive : style.wtsp} onClick={() => setActive(SEND_METHODS.WHATSAPP)}>WhatsApp</button>
                </div>
                {
                  active === SEND_METHODS.EMAIL ? (
                    <div className={style.inputWrapper}>
                      <label htmlFor="">{ t('receipt.send_receipt_email') }</label>
                      <div className={style.sendWrapper}>
                        <input
                          type="text"
                          placeholder="example@kassa.kz"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          className={emailError && style.errorInput}
                        />
                        <button onClick={() => sendEmail()} className={sending && style.sending}>
                          {sending ? (
                            <Spinner />
                          ) : (
                            t('receipt.send')
                          )}
                        </button>
                      </div>
                      {
                        emailError && <p className={style.error}>{emailError}</p>
                      }
                    </div>
                  ) : (
                    <div className={style.inputWrapper}>
                      <label htmlFor="">{ t('receipt.send_receipt_whatsapp') }</label>
                      <div className={style.sendWrapper}>
                        <MaskedInput
                          showMask={false}
                          guide={true}
                          placeholderChar={'\u2000'}
                          mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                          inputMode="tel"
                          id="inputPhone"
                          name="number"
                          className={phoneError && style.errorInput}
                          placeholder="+7 (777) 000-00-00"
                          autoComplete="phone"
                          required
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          onFocus={(e) => e.target.value = "+7"}
                          onBlur={(e) => e.target.placeholder = "+7 (777) 000-00-00"}
                        />
                        <button onClick={() => sendWa()} className={sending && style.sending}>
                          {sending ? (
                            <Spinner />
                          ) : (
                            t('receipt.send')
                          )}
                        </button>
                      </div>{
                        phoneError && <p className={style.error}>{phoneError}</p>
                      }
                    </div>
                  )
                }
              </div>
              <div className={style.formFooter}>
                <p onClick={() => closeModal()}>{ t('receipt.close') }</p>
              </div>
            </div>
          </div>
        )
      }

      <ToastContainer
        position="top-center"
        autoClose={5000}
        transition={Slide}
        icon={MyIcon}
        closeButton={false}
        hideProgressBar
        limit={1}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  )
}

export default Check;
