export const DISCOUNT_TYPES = {
  PERCENT: 'percent',
  FIXED: 'fixed'
}
  
export const FISCAL_DEV_HOST = 'cabinet.fiscalv3.tech24.kz';
export const FISCAL_DEV_URL = 'https://cabinet.fiscalv3.tech24.kz';

export const FISCAL_API_DEV_URL = 'https://api.fiscalv3.tech24.kz';
export const FISCAL_API_PROD_URL = 'https://fiscal2.kassa24.kz';
export const PULT_API_DEV_URL = 'https://api.pult24.test.tech24.kz';
export const PULT_API_PROD_URL = 'https://api.pult24.kz';
export const PULT_SITE_URL = 'https://test.pult24.kz';
export const MERCHANT_CABINET_URL = 'https://business.kassa24.kz/cabinet';
export const WEBTERMINAL_DEV_URL = 'https://webterminal.test.tech24.kz';
export const WEBTERMINAL_PROD_URL = 'https://webterminal.kassa24.kz';
export const WHATSAPP_API_URL = 'https://api.whatsapp.com';
export const AMPLITUDE_API_URL = 'https://api2.amplitude.com';

export const KGD_URL = 'https://kgd.gov.kz';
export const PULT_DEV_URL = 'https://pult-test.kassa24pay.kz';
export const DIGITAL_OCEAN_URL = 'https://pult.ams3.digitaloceanspaces.com';

export const COMPANIES_IDS_WITH_FIXED_DISCOUNT = [19688, 19687, 19678];
export const IS_DEV = window.location.hostname === FISCAL_DEV_HOST || window.location.hostname === 'localhost';

export const OPERATION_TYPES = {
  SALE: { ID: 1, KEY: 'sale' }, // продажа
  INCOME: { ID: 2, KEY: 'income' }, // служебный приход
  OUTCOME: { ID: 3, KEY: 'outcome' }, //  служебный расход
  PURCHASE: { ID: 4, KEY: 'purchase' }, // покупка
  REFUND: { ID: 5, KEY: 'refund' }, // возврат продажи
  PURCHASE_REFUND: { ID: 6, KEY: 'purchase_refund' }, // возврат покупки
};
