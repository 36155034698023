import React, { useState, useEffect } from "react";
import style from "../../styles/pages/pays.module.scss"
import { ReactComponent as Swap } from "../images/swap.svg";
import { ReactComponent as Info } from "../images/info.svg";
import { ReactComponent as Spinner } from "../images/spinner.svg"
import { ReactComponent as Wallet } from "../../svg/wallet-3-line.svg"
import { ReactComponent as BankCard } from "../../svg/bank-card-line.svg"
import { ReactComponent as Transfer } from "../../svg/transfer.svg"
import { useTranslation } from "react-i18next";
import { OPERATION_TYPES } from "../../../../constants/app";

const NotEnough = ({ data }) => {
  return (
    <p className={style.sum}>
      {(data.Total - (data.Income.Cash + data.Income.NonCash + data.Income.Mobile)).toFixed(2)} ₸
    </p>
  )
}

const CostFormat = (value) => {
  if (value.toString().length) {
    let str = value.toString().split('.')
    let str1 = str[0]
    let str2 = str[1]

    str1 = str1.split("").reverse().join("");
    str1 = str1.match(/.{1,3}/g)
    str1 = str1.join(" ")
    str1 = str1.split("").reverse().join("");
    return `${str1}.${str2 || '00'}`
  } else {
    return value
  }
}

const Pays = ({context, history, status, showPayTypes, showChanges, showPlus, adaptive, addPositionIsHidden, PurchaseRefund, iinTitle}) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  useEffect(() => {
    if (context && context.CheckImg) {
      history.push(`/www/kkms/${kkm.Id}/check/success`);
    }
  }, [context]);

  const [paying, setPaying] = useState(false)

  const handleInputChange = (e, func, val, objName) => {
    let value = e.target.value.replace(/\s/g, '')
    if (!isNaN(value) && Number(value) >= 0) {
        func({ value, name: val, objName });
    }
  }

  const swap = () => context.swapSums()

  const pay = () => {
    if (adaptive) {
      setPaying(true)
      if (status === OPERATION_TYPES.SALE.KEY) {
        context.makeSale('sales').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.PURCHASE.KEY) {
        context.makePurchase('purchases').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.REFUND.KEY) {
        context.makeRefund('refunds').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.PURCHASE_REFUND.KEY) {
        context.makeRefund('purchase_refunds').finally(() => setPaying(false))
      }
    } else {
      if (!context.Position.Name) {
        context.setNameError(true)
      } else {
        context.setNameError(false)
      }
      if (!context.Position.Price) {
        context.setPriceError(true)
      } else {
        context.setPriceError(false)
      }
      if (
        context.Position.Name && 
        context.Position.Price && 
        context.Total <= (context.Income.Cash + context.Income.NonCash + context.Income.Mobile)
      ) {
        setPaying(true)
        if (status === OPERATION_TYPES.SALE.KEY) {
          context.makeSale('sales').finally(() => setPaying(false))
        } else if (status === OPERATION_TYPES.PURCHASE.KEY) {
          context.makePurchase('purchases').finally(() => setPaying(false))
        } else if (status === OPERATION_TYPES.REFUND.KEY) {
          context.makeRefund('refunds').finally(() => setPaying(false))
        } else if (status === OPERATION_TYPES.PURCHASE_REFUND.KEY) {
          context.makeRefund('purchase_refunds').finally(() => setPaying(false))
        }
      }
    }
  }

  const payRefund = () => {
    if (!(context.Total <= (context.Income.Cash + context.Income.NonCash + context.Income.Mobile))) {
      return
    }

    setPaying(true)
    PurchaseRefund ? context.makeRefund('purchase_refunds').finally(() => setPaying(false)) :
                    context.makeRefund('refunds').finally(() => setPaying(false))
  }

  const iinFormat = (value) => {
    if (value) {
      let str = value.replace(/\s/g, '')
      str = str.match(/.{1,3}/g)
      str = str.join(" ")
      return str
    }
    return value
  }

  return (
    <div className={style.pays}>
      <div className={style.header}>
        <p className={style.title}>{ t('receipt.payment') }</p>
      </div>
      <div className={style.body}>

        {
          showPayTypes && (
            (status === OPERATION_TYPES.SALE.KEY || status === OPERATION_TYPES.PURCHASE.KEY) ? (
              <div className={style.inputWrapper}>
                <div className={style.doubleWrapper}>
                  <div className={style.cash}>
                    <Wallet />
                    <label htmlFor="">{ t('receipt.cash') }</label>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={context.Income.Cash === 0 ? '' : context.Income.Cash}
                      onChange={ e => handleInputChange(e, context.cashHandler, 'Cash', 'Income') }
                    />
                  </div>
                  <button className={style.swap} onClick={() => swap()}>
                    <Swap />
                  </button>
                  <div className={style.nonCash}>
                    <BankCard/>
                    <label htmlFor="">{ t('receipt.card') }</label>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={context.Income.NonCash === 0 ? '' : context.Income.NonCash}
                      onChange={ e => handleInputChange(e, context.cashHandler, 'NonCash', 'Income') }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={style.inputWrapper}>
                <div className={style.iinWrapper}>
                  <Wallet />
                  <label htmlFor="">{ t('receipt.amount') }</label>
                </div>
                <input
                  type="text"
                  placeholder="0.00"
                  value={context.Income.NonCash === 0 ? context.Income.Cash : context.Income.NonCash}
                  onChange={ e => handleInputChange(e, context.cashHandler, context.Income.NonCash === 0 ? 'Cash' : 'NonCash', 'Income') }
                />
              </div>
            )
          )
        }

        { (status === OPERATION_TYPES.SALE.KEY) && <div className={style.inputWrapper}>
          <div className={style.iinWrapper}>
            <Transfer />
            <label htmlFor="">{ t('receipt.transfer') }</label>
            <Info />
            <div className={style.popup}>
              { t('other.non_cash_payments_received') }
            </div>
          </div>
          <input
            type="text"
            placeholder="0.00"
            value={context.Income.Mobile === 0 ? '' : context.Income.Mobile}
            onChange={ e => handleInputChange(e, context.cashHandler, 'Mobile', 'Income') }
          />
        </div> }

        <div className={style.inputWrapper}>
          <div className={style.iinWrapper}>
            <label htmlFor="">{`${t('receipt.customer_tax_id').split('|')[0]} ${iinTitle ? iinTitle : t('receipt.customer_tax_id').split('|')[1]}`}</label>
            <Info />
            <div className={style.popup}>
              { t('other.iin_bin_optional') }
            </div>
          </div>
          <input
            type="text"
            maxLength={15}
            placeholder="000 000 000 000"
            value={iinFormat(context.CustomerIin)}
            onChange={ e => handleInputChange(e, context.iinHandler) }
          />
          {
            context.CustomerIinError && <p className={style.errorText}>{ t('other.enter_valid_bin_iin') }</p>
          }
        </div>

        {
          context.Total > (context.Income.Cash + context.Income.NonCash + context.Income.Mobile) && (
            <div className={style.errorWrapper}>
              <p className={style.title}>{ t('other.insufficient_funds_for_operation') }:</p>
              <NotEnough data={context}/>
            </div>
          )
        }

        <div className={style.totalWrapper}>
          <div className={style.row}>
            <p className={style.key}>{ t('receipt.total_to_pay') }</p>
            <p className={style.value}>{CostFormat(context.Total)} ₸</p>
          </div>
          {
            showChanges && (
              <div className={style.row}>
                <p className={style.key}>{ t('receipt.change') }</p>
                <p className={style.value}>{CostFormat(context.Change)} ₸</p>
              </div>
            )
          }
        </div>

        <div className={style.actions}>
          <button
            className={style.pay}
            disabled={!context.NewPreCheck[0].id || !context.Total || paying}
            onClick={() => !addPositionIsHidden ? pay() : payRefund()}
          >
            {
              paying ? (
                <Spinner />
              ) : (
                t('receipt.issue_receipt')
              )
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pays;
